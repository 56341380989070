import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, TextField, Typography, Box, Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { useDebounce } from 'use-debounce';
import * as _ from 'lodash';
import axios from 'axios';
// import AnimatedCard from '../../components/card-image-title-description/animated-card';

const KnowledgeHubYearIndexPage = ({ pageContext: { knowledgeHubByYearArray, _year, knowledgeHubYears } }) => {
  const [years, setYears] = useState([]);
  const [hasResult, setHasResult] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredKnowledge, setfilteredKnowledge] = useState([]);
  const [categories, setCategories] = useState([]);
  const [value] = useDebounce(searchText, 1000);
  const [filters, setFilters] = useState({
    type: '',
    category: '',
    text: '',
    year: _year,
  });

  useEffect(() => {
    let _filteredYears = [];
    let _allYears = years;

    if (filters.text.length > 0 || filters.type.length > 0 || filters.category.length > 0) {
      let _filteredKnowledgeHub = [];

      let searchObj = {};

      if (filters.category.length > 0) searchObj = { ...searchObj, category: [{ code: filters.category }] };
      if (filters.type.length > 0) searchObj = { ...searchObj, type: filters.type };

      _filteredKnowledgeHub = _.filter(knowledgeHubByYearArray, searchObj);

      if (filters.text.length > 0 && _filteredKnowledgeHub.length === 0) {
        _filteredKnowledgeHub = knowledgeHubByYearArray.filter(
          (_knowledgeHub) =>
            _knowledgeHub.title.toLowerCase().includes(filters.text.toLocaleLowerCase()) ||
            _knowledgeHub.description.toLowerCase().includes(filters.text.toLocaleLowerCase())
        );
      } else {
        _filteredKnowledgeHub = _filteredKnowledgeHub.filter(
          (_knowledgeHub) =>
            _knowledgeHub.title.toLowerCase().includes(filters.text.toLocaleLowerCase()) ||
            _knowledgeHub.description.toLowerCase().includes(filters.text.toLocaleLowerCase())
        );
      }

      _filteredYears = Array.from(new Set(_filteredKnowledgeHub.map((_knowledgeHub) => _knowledgeHub.year)));

      if (_filteredKnowledgeHub.length > 0) {
        setHasResult(true);
        setfilteredKnowledge(_filteredKnowledgeHub);
        setYears(_filteredYears);
      } else {
        setHasResult(false);
        setYears([]);
      }
    } else {
      setHasResult(true);
      setfilteredKnowledge(knowledgeHubByYearArray);
      setYears(_allYears);
    }

    return () => {};
  }, [filters]);

  useEffect(() => {
    setYears(knowledgeHubYears.sort((a, b) => b - a));
    setfilteredKnowledge(knowledgeHubByYearArray);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_CMS_API_URL}/utility/categories/knowledge-hub`)
      .then((_response) => {
        if (_response.status === 200) setCategories(_response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Layout>
      <SEO lang='en' title={` Knowledge Hub | Global Manufacturing & Industrialisation Summit | GMIS${_year}`} />
      <SectionContainerLayoutWithFilter
        title={`KNOWLEDGE HUB ${_year}`}
        baseLink='/knowledge-hub'
        filters={years}
        isViewAll
        isDivider>
        <Grid container direction='row' spacing={3}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                placeholder='Search Knowledge Hub'
                label='Search Knowledge Hub'
                color='secondary'
                variant='outlined'
                fullWidth
                onChange={(e) => setFilters({ ...filters, text: e.target.value })}
              />
            </Grid>
            <Grid item xs={6} spacing={3}>
              <Grid container xs={12}>
                <Grid item xs={12} sm={6}>
                  <FormControl color='secondary' variant='outlined' fullWidth>
                    <InputLabel id='type-label'>Type</InputLabel>
                    <Select
                      labelId='type-label'
                      variant='outlined'
                      label='Type'
                      onChange={(e) => {
                        setFilters({ ...filters, type: e.target.value });
                      }}>
                      {/* <MenuItem value='pdf'>PDF</MenuItem> */}
                      {/* <MenuItem value=''></MenuItem> */}
                      <MenuItem value='pdf'>Report</MenuItem>
                      <MenuItem value='article'>Article</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4}>
                <FormControl color='secondary' variant='outlined' fullWidth>
                  <InputLabel id='category-label'>Category</InputLabel>
                  <Select
                    labelId='category-label'
                    variant='outlined'
                    label='Category'
                    onChange={(e) => {
                      setFilters({ ...filters, category: e.target.value });
                    }}>
                    {categories && categories.length > 0 ? (
                      categories.map((_category, index) => (
                        <MenuItem key={index} value={_category.code}>
                          {_category.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>No available categories</MenuItem>
                    )}
                    <MenuItem value=''>Clear</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          {hasResult ? (
            filteredKnowledge.map((_knowledgeHub, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={3}>
                <CardImageTitleDescription
                  isImgTag
                  image={
                    _knowledgeHub.file[0].type !== 'application/pdf'
                      ? _knowledgeHub.file[0].code
                      : _knowledgeHub.file[1].code
                  }
                  title={_knowledgeHub.title}
                  body={_knowledgeHub.description}
                  readMore={`/knowledge-hub/${_year}/${_knowledgeHub.urlSlug}`}
                  lines={6}
                  height={300}
                  type={_knowledgeHub.type === 'pdf' ? 'Report' : _knowledgeHub.type}
                />
                {/* <AnimatedCard
                  title={_knowledgeHub.title}
                  body={_knowledgeHub.description}
                  image={
                    _knowledgeHub.file[0].type !== 'application/pdf'
                      ? _knowledgeHub.file[0].code
                      : _knowledgeHub.file[1].code
                  }
                  height={300}
                  date={_knowledgeHub.date}
                  type={_knowledgeHub.type}
                  readMore={`/knowledge-hub/${_year}/${_knowledgeHub.urlSlug}`}
                /> */}
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                <Typography color='textSecondary' variant='button'>
                  No Knowledge Found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default KnowledgeHubYearIndexPage;
